<aecom-form *ngIf="isLoaded" columns="12" class="contentcontainer">
  <aecom-form-group *ngIf="items.length > 0" columnSpan="12" columns="1" title="Internal Notes" style="margin-bottom: 40px">
    <aecom-form-item columnSpan="1">
      <ng-container *ngFor="let item of items">
        <div class="responseBox" [ngClass]="{'rejectedBox': item.IsBack}">
          <div class="responseHeader">
            <span>{{ item.Name }}</span>
            <span class="responseHeaderSeparate_b">|</span>
            <span>{{ item.Role }}</span>
            <div class="responseHeaderDate">{{ item.FormatedDateUpdated }}</div>
            <div class="responseHeaderCollapse" (click)="item.Collapse = !item.Collapse">
              <i
                [ngClass]="
                  item.Collapse
                    ? 'icon-action_arrow_down'
                    : 'icon-action_arrow_up'
                "
                class="icon icon-blue icon-lg"
              ></i>
            </div>
          </div>
          <div class="responseContent" *ngIf="!item.Collapse">
            <div class="responseBody">
              <div class="responseBodySubTitle">{{item.Title}}</div>
              <div class="responseBodySubContent" [innerHtml]="item.Notes"></div>
            </div>
            <div class="responseBody" *ngIf="item.ScheduleImpact || item.CostImpact">
              <div class="flexBox">
                <div class="halfBox">
                  <div class="responseBodySubTitle">Schedule Impact (Internal)</div>
                  <div class="responseBodySubContent">{{item.ScheduleImpact}}</div>
                </div>
                <div class="halfBox">
                  <div class="responseBodySubTitle">Cost Impact (Internal)</div>
                  <div class="responseBodySubContent">{{item.CostImpact}}</div>
                </div>
              </div>
            </div>
            <div class="responseBody" *ngIf="item.Attachments && item.Attachments.length > 0">
              <bbj-file-list
                [fileList]="item.Attachments"
                title="Attachments"
                [allowMovingFiles]="allowMovingFiles"
                (movedFile)="attachmentOut($event)"
                (movedFiles)="attachmentsOut($event)"
              ></bbj-file-list>
            </div>
          </div>
        </div>
      </ng-container>
    </aecom-form-item>
  </aecom-form-group>
  <aecom-form-group columnSpan="12" columns="2" title="Processing Metadata" style="margin-bottom: 40px; position: relative;">
    <aecom-form-item [label]="'Internal Due Date'" columnSpan="1">
      <div
        class="itemText"
        *ngIf="entity.InternalDueDate; else notProvide"
      >
        {{ getFixedDateTime(entity.InternalDueDate) }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Target Due Date'" columnSpan="1">
      <div
        class="itemText"
        *ngIf="entity.DueDate; else notProvide"
      >
        {{ getFixedDateTime(entity.DueDate) }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Schedule Impact (Internal)'" columnSpan="1">
      <div class="itemText" *ngIf="scheduleImpace; else notProvide">
        {{ scheduleImpace }}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Cost Impact (Internal)'" columnSpan="1">
      <div class="itemText" *ngIf="costImpace; else notProvide">
        {{ costImpace }}
      </div>
    </aecom-form-item>
    <div *ngIf="canUpdateDueDate" style="position: absolute; top: 7px; right: 0; cursor: pointer;">
      <div class="formGroupManu" (click)="updateDueDate()">
        UPDATE DUE DATE
      </div>
    </div>
  </aecom-form-group>
  <aecom-form-group columnSpan="12" columns="2" title="Processing Roles" style="margin-bottom: 40px">
    <aecom-form-item [label]="'Doc Control'" columnSpan="1">
      <div class="itemText" *ngIf="docControlUser; else (showDocControl===true && notAvailable) || notProvide">
        {{ docControlUser?.displayName }} ({{ docControlUser?.mail }})
      </div>
    </aecom-form-item>

    <aecom-form-item [label]="'Coordinator'" columnSpan="1">
      <div class="itemText" *ngIf="coordinator; else (showCoordinator===true && notAvailable) || notProvide">
        {{ coordinator.displayName }} ({{ coordinator.mail }})
      </div>
    </aecom-form-item>

    <aecom-form-item [label]="'Project Manager'" columnSpan="1">
      <div class="itemText" *ngIf="manager; else (showManager===true && notAvailable) || notProvide">
        {{ manager.displayName }} ({{ manager.mail }})
      </div>
    </aecom-form-item>

    <aecom-form-item [label]="'Sr. Project Manager'" columnSpan="1">
      <div class="itemText" *ngIf="srManager; else (showSrManager===true && notAvailable) || notProvide">
        {{ srManager.displayName }} ({{ srManager.mail }})
      </div>
    </aecom-form-item>

    <aecom-form-item [label]="'DDC Manager'" columnSpan="1">
      <div class="itemText" *ngIf="ddcManager; else (showDDCManager===true && notAvailable) || notProvide">
        {{ ddcManager.displayName }} ({{ ddcManager.mail }})
      </div>
    </aecom-form-item>
  </aecom-form-group>
  <aecom-form-group *ngIf="showWorkflow" columnSpan="12" columns="1" title="Workflow History" style="margin-bottom: 40px">
    <workflowHistory [data]="workflowHistorys" [showDays]="true"></workflowHistory>
  </aecom-form-group>
</aecom-form>

<ng-template #notAvailable>
  <div class="itemText">N/A</div>
</ng-template>

<ng-template #notProvide>
  <div class="itemText">-</div>
</ng-template>
