<ngx-spinner
  name="spinner"
  [fullScreen]="true"
  type="timer"
  size="medium"
  bdColor="#000000"
  template="<img src='assets/img/bbj-pmis-load-icon.svg'/> "
>
</ngx-spinner>

<div class="content">
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
