import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import _ from "lodash";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { RFIItemService } from "../api-generated";
import { IPRFIListItem } from "../api-generated/model/iPRFIListItem";
import LoadingService from "../services/loading.service";
import RouteParams from "../shared/route-params";

@Injectable({
  providedIn: "root",
})
export default class RfiListResolver {
  constructor(
    public router: Router,
    public loadingService: LoadingService,
    public rfiItemService: RFIItemService,
    public activatedRoute: ActivatedRoute,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<IPRFIListItem[]> {
    this.loadingService.start();
    const contractId = route.parent.params[RouteParams.ContractId];

    return this.rfiItemService.getAllRFIItems(contractId).pipe(
      map((res) => {
        return _.orderBy(_.orderBy(res, "DueDate", ["desc"]), "ViewOnly");
      }),
      catchError((error: HttpErrorResponse) => {
        this.router.navigate([
          contractId,
          "error",
          error.status ? error.status : 500,
        ]);
        return of(null);
      }),
    );
  }
}
