enum RFISelectUser {
  ReviewerTitle = "REVIEWER(S) - Optional",
  ObserverTitle = "OBSERVER(S) - Optional",
  ReviewerBtn = "+ Add REVIEWER",
  ObserverBtn = "+ Add OBSERVERS",
  ReviewerWindowTitle = "Assign Reviewers",
  ObserverWindowtitle = "Assign Observers"
}

export default RFISelectUser;
