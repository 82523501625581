<aecom-form columns="12" class="contentcontainer">
  <aecom-form-item class="itemBlock" [label]="'Issued By'" columnSpan="6">
    <div class="itemText">{{ rfiDetail.originator }}</div>
  </aecom-form-item>

  <aecom-form-item class="itemBlock" [label]="'Issue Date'" columnSpan="6">
    <div class="itemText">{{ rfiDetail.issuedDate }}</div>
  </aecom-form-item>

  <aecom-form-item class="itemBlock" [label]="'Responded By'" columnSpan="6">
    <div class="itemText">{{ rfiDetail.responder?.displayName || 'N/A'}}</div>
  </aecom-form-item>

  <aecom-form-item class="itemBlock" [label]="'Response Date'" columnSpan="6">
    <div class="itemText">{{ rfiDetail.respondedDate || 'N/A'}}</div>
  </aecom-form-item>

  <aecom-form-item class="itemBlock" [label]="'Title'" columnSpan="12">
    <div class="itemText" *ngIf="rfiDetail.title; else notAvailable">{{ rfiDetail.title }}</div>
  </aecom-form-item>

  <aecom-form-item class="itemBlock" [label]="'Question'" columnSpan="12">
    <div *ngIf="rfiDetail.question; else notAvailable" class="itemText" [innerHtml]="rfiDetail.question">
    </div>
  </aecom-form-item>

  <aecom-form-item class="itemBlock" [label]="'Proposed Solution'" columnSpan="12">
    <div *ngIf="rfiDetail.proposedSolution; else notAvailable" class="itemText" [innerHtml]="rfiDetail.proposedSolution">
    </div>
  </aecom-form-item>

  <aecom-form-item class="itemBlock" [label]="'Anticipated Next Step'" columnSpan="12">
    <div *ngIf="rfiDetail.anticipatedNextStep; else notAvailable" class="itemText" [innerHtml]="rfiDetail.anticipatedNextStep">
    </div>
  </aecom-form-item>

  <aecom-form-item columnSpan="6">
    <bbj-file-list
      title="Attachments"
      [fileList]="docFiles"
      [allowMovingFiles]="false"
    ></bbj-file-list>
  </aecom-form-item>

  <aecom-form-item class="itemBlock" columnSpan="6"></aecom-form-item>

  <aecom-form-group columnSpan="12" columns="2" title="Metadata" class="marginTop">
    <aecom-form-item class="itemBlock" [label]="'Phase'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.phase; else notAvailable">{{ rfiDetail.phase}}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'Classification'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.classification; else notAvailable">{{ rfiDetail.classification}}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'Drawing and Detail Ref'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.drawingAndDetailRef; else notAvailable">{{ rfiDetail.drawingAndDetailRef}}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'Contract Requirements'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.contractRequirement; else notAvailable">{{ rfiDetail.contractRequirement }}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'UniFormat Level 1 Category'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.division; else notAvailable">{{ rfiDetail.division }}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'UniFormat Level 2 Category'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.specification; else notAvailable">{{ rfiDetail.specification }}</div>
    </aecom-form-item>

    <!-- <aecom-form-item class="itemBlock" [label]="'MasterFormat Specification Section'" columnSpan="1">
      <div class="itemText">{{ rfiDetail.masterSpec }}</div>
    </aecom-form-item> -->

    <aecom-form-item class="itemBlock" [label]="'Anticipated Schedule Impact'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.anticipatedScheduleImpact; else notAvailable">{{ rfiDetail.anticipatedScheduleImpact }}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'Anticipated Cost Impact'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.anticipatedCostImpact; else notAvailable">{{ rfiDetail.anticipatedCostImpact  }}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'Schedule Impact Details'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.scheduleImpactDetails; else notAvailable" [innerHtml]="rfiDetail.scheduleImpactDetails"></div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'Cost Impact Details'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.costImpactDetails; else notAvailable" [innerHtml]="rfiDetail.costImpactDetails"></div>
    </aecom-form-item>
  </aecom-form-group>

  <aecom-form-group columnSpan="12" columns="1" title="Additional Details">
    <aecom-form-item class="itemBlock" [label]="'Internal Reference Number'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.contractorReference; else notAvailable">{{ rfiDetail.contractorReference }}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'Priority Note'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.note; else notAvailable" [innerHtml]="rfiDetail.note"></div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'Processing Note'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.processingNotes; else notAvailable" [innerHtml]="rfiDetail.processingNotes"></div>
    </aecom-form-item>
  </aecom-form-group>
</aecom-form>

<ng-template #notAvailable>
  <div class="itemText">N/A</div>
</ng-template>
