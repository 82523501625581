import { FileModel } from "@aecom/core";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import RFIRole from "@models/rfiRoles";
import RFIStatus from "@models/rfiStatus";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalUserService from "@services/local-user.service";
import { combineUserInfo, getWorkflowStatusArray } from "@shared/utils";
import { IPRFIDocumentDownload, IPRFIItem, IUser, RfiReviewer, RfiWatcher } from "src/app/api-generated";
import { Router } from "@angular/router";
import BaseFunctionService from "@services/baseFunction.service";
import { AuthService } from "@bbj/components";

@Component({
  selector: "leftPanel",
  templateUrl: "./leftPanel.component.html",
})
export default class LeftPanelComponent implements OnInit {
  @Input() defaultTab = "details";

  @Input() allowMovingFiles = false;

  @Input() reviewersResponse: (RfiReviewer & {UserInfo?: IUser})[] = [];

  @Input() watchersResponse: (RfiWatcher & {UserInfo?: IUser})[] = [];

  @Output() moveAttachment = new EventEmitter<FileModel>();

  @Output() moveAttachments = new EventEmitter<FileModel[]>();

  entity: IPRFIItem;

  docs: IPRFIDocumentDownload[];

  IsDbAdmin = false;

  showProcessing = false;

  showResponseTab = false;

  isCompile = false;

  constructor(
    public authService: AuthService, 
    public localContractUserService: LocalContractUserService, 
    public localUserService: LocalUserService, 
    private router: Router, 
    public baseFunctionService: BaseFunctionService
  ){}
  
  ngOnInit(): void {
    this.entity = this.baseFunctionService.getEntity();
    this.docs = this.baseFunctionService.getDocs();
    const role = this.localContractUserService.currentUserContractRole;
    const userId = this.authService.getUserId();
    this.IsDbAdmin = role === RFIRole.DBAdmin;
    this.isCompile = this.entity.Status === RFIStatus.Review_Period && userId === this.entity.CoordinatorId && this.router.url.includes('compilingResponse');

    if(!this.IsDbAdmin)
    {
      this.showProcessing = true;
      const users = this.localUserService.getItems();
      this.reviewersResponse = this.entity.rfi_reviewer.map((item) => {
        const user = users.find((u)=>{return u.id === item.ReviewerId});
        return combineUserInfo<RfiReviewer>(item, user);
      });
      this.watchersResponse = this.entity.rfi_watcher.map((item) => {
        const user = users.find((u)=>{return u.id === item.WatcherId});
        return combineUserInfo<RfiWatcher>(item, user);
      });
      this.showResponseTab = getWorkflowStatusArray(this.entity).includes(RFIStatus.Review_Period);
    }
    
  }

  attachmentOut(item: FileModel): void {
    // console.log(item);
    this.moveAttachment.emit(item);
  }

  attachmentsOut(items: FileModel[]): void {
    // console.log(item);
    this.moveAttachments.emit(items);
  }
}
