<div class="maincontainer light-theme" *ngIf="entity">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel 
        [defaultTab]="defaultTab" 
        [allowMovingFiles]="true" 
        (moveAttachment)="addFileToAttachment($event)"
        (moveAttachments)="addFilesToAttachment($event)"
      ></leftPanel>
      
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button innerClass="btn lg outline orange" (click)="back()">
                back
              </aecom-button>

              <aecom-button
                [theme]="'light'"
                [disabled]="!hasChanges() || newItem.hasComments === false ? true : null"
                innerClass="btn lg solid green"
                (click)="saveDraft()"
              >
                save draft
              </aecom-button>

              <aecom-button
                innerClass="btn lg solid blue"
                (click)="submitRFI()"
              >
                Submit
              </aecom-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="newItem">
        <div class="col-12">
          <aecom-form columns="6">
            <aecom-form-group
              columnSpan="6"
              columns="2"
              title="RFI RESPONSE"
              style="margin-bottom: 40px"
              class="matchToTab"
            >
              <aecom-form-item
                data-cy="hasComments"
                [label]="'Do you have comments to provide?'"
                columnSpan="1"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      newItem.hasComments
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setHasComments(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      !newItem.hasComments
                        ? 'blue'
                        : 'bg-grey-e'
                    }}"
                    (click)="setHasComments(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
              <aecom-form-item
                *ngIf="newItem.hasComments"
                [label]="'Response'"
                columnSpan="2"
                [property]="newItem.Response.length + '/1500 characters'"
              >
                <aecom-textbox
                  [theme]="'light'"
                  [(ngValue)]="newItem.Response"
                  placeholder="Response"
                  rows="6"
                  [maxlength]="1500"
                  [isMultiLine]="true"
                  (ngValueChange)="setResponse($event)"
                  [validate]="newItem.Response.length === 0 && canIssueRFI || baseFunctionService.isInputInvalid(newItem.Response)"
                ></aecom-textbox>

                <div
                  class="error-message"
                  *ngIf="
                    canIssueRFI && newItem.Response.length === 0 && canIssueRFI && newItem.hasComments
                  "
                >
                  Required Field
                </div>
              </aecom-form-item>
            </aecom-form-group>

            <aecom-form-group
              *ngIf="newItem.hasComments"
              columnSpan="6"
              columns="1"
              title="Attachments"
              style="margin-bottom: 40px"
            >
              <aecom-form-item>
                <aecom-upload
                  [isNewStyle]="true"
                  [multiFiles]="true"
                  [canDelete]="true"
                  [data]="(baseFunctionService.getBBJFileListItemAttachment().fileList$ | async)"
                  [theme]="'light'"
                  [labelText]="'Uploaded Attachments'"
                  [headerWidth]="'50%'"
                  (ngValueChange)="importFile($event)"
                  (ngDeleteChange)="deleteFile($event)"
                ></aecom-upload>
                <div class="error-message" *ngIf="!(baseFunctionService.getBBJFileListItemAttachment().fileListNameValid$ | async)">
                  File Name cannot include special characters other than '-', '_', '.', '@'
                </div>
              </aecom-form-item>
            </aecom-form-group>
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>
