import { DOCUMENT } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable, of, from, forkJoin } from "rxjs";
import { catchError, concatMap, first, map } from "rxjs/operators";
import environment from "src/environments/environment";
import {
  INavigationRoot,
  IPRFIClassification,
  IPRFIContractRequirement,
  IPRFISpecification,
  IUser,
  NavigationService,
  Phase,
  PhaseService,
  TPContract,
  TPContractUser,
  UserService,
  ContractService,
  ContractUserService,
  RFIClassificationService,
  RFIContractRequirementService,
  RFIItemService,
  RFISpecificationService,
  ApplicationRoleService,
  ApplicationRole,
} from "../api-generated";
import IContractUserWithUserInfo from "../models/IContractUserWithUserInfo";
import LoadingService from "../services/loading.service";
import LocalClassificationService from "../services/local-classification.service";
import LocalContractRequirementService from "../services/local-contractRequirement.service";
import LocalContractService from "../services/local-contract.service";
import LocalContractUserService from "../services/local-contractUser.service";
import LocalPhaseService from "../services/local-phase.service";
import LocalSpecificationService from "../services/local-specification.service";
import LocalUserService from "../services/local-user.service";
import NavService from "../services/nav.service";
import RouteParams from "../shared/route-params";
import { rfiAppId, rfiDocRoleId } from "../shared/staticValue";
import { combineUserInfo } from "../shared/utils";
import LocalApplicationRoleService from "@services/local-application_role.service";
import { AuthService } from "@bbj/components";

@Injectable({
  providedIn: "root",
})
export default class LoginUserResolve {
  constructor(
    private loadingService: LoadingService,
    private authService: AuthService,
    public router: Router,
    private contractService: ContractService,
    private contractUserService: ContractUserService,
    private userService: UserService,
    private rfiSpecificationService: RFISpecificationService,
    private rfiClassificationService: RFIClassificationService,
    private rfiContractRequirementService: RFIContractRequirementService,
    private phaseService: PhaseService,
    private rfiItemService: RFIItemService,
    private applicationRoleService: ApplicationRoleService,
    public localContractUserService: LocalContractUserService,
    public localUserService: LocalUserService,
    public localContractService: LocalContractService,
    public localSpecificationService: LocalSpecificationService,
    public localClassificationService: LocalClassificationService,
    public localContractRequirementService: LocalContractRequirementService,
    public localApplicationRoleService: LocalApplicationRoleService,
    public localPhaseService: LocalPhaseService,
    public navService: NavService,
    public navigationService: NavigationService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  // eslint-disable-next-line sonarjs/cognitive-complexity
  resolve(route: ActivatedRouteSnapshot): Observable<void> {
    function sortByName(a: { Name: string }, b: { Name: string }): number {
      if (a.Name.toLocaleLowerCase() > b.Name.toLocaleLowerCase()) {
        return 1;
      }
      return -1;
    }
    function sortByTitle(a: { Title: string }, b: { Title: string }): number {
      if (a.Title.toLocaleLowerCase() > b.Title.toLocaleLowerCase()) {
        return 1;
      }
      return -1;
    }
    this.loadingService.start();
    console.log("Hitting this");
    const contractId = route.params[RouteParams.ContractId];
    if (!contractId) {
      this.document.location.href = environment.url.portal;
    }

    return from(this.authService.login()).pipe(
      concatMap((userId) => {
        if (!userId) {
          return of([]);
        }
        const localContract = this.localContractService.getItem();
        const localContractUsers = this.localContractUserService.getItems();
        const localUsers = this.localUserService.getItems();
        const localClassifications = this.localClassificationService.getItems();
        const localContractRequirements =
          this.localContractRequirementService.getItems();
        const localPhases = this.localPhaseService.getItems();
        const localSpecifications = this.localSpecificationService.getItems();
        const localRole = this.localContractUserService.currentUserContractRole;
        const localApplicationRoles = this.localApplicationRoleService.getAppRoles();
        const boroughNavigation = this.navService.getBroughData();
        const combinedBoroughs = this.navService.getrecentvalue();
        const globalNavigation = this.navService.getGlobalData();
        if (
          !localContract ||
          !localContractUsers ||
          !localUsers ||
          !localApplicationRoles ||
          !localClassifications ||
          !localContractRequirements ||
          !localPhases ||
          !localSpecifications ||
          !localRole ||
          localContract.Guid !== contractId
        ) {
          return forkJoin([
            this.userService.getAll(),
            this.contractService.getContractById(contractId),
            this.contractUserService.getContractUser(contractId),
            this.applicationRoleService.getAllApplicationRole(),
            this.rfiItemService.getRFIRole(contractId),
            this.rfiSpecificationService.getAllRFISpecification(),
            this.rfiClassificationService.getAllRFIClassification(),
            this.rfiContractRequirementService.getAllRFIContractRequirement(),
            this.phaseService.getAllPhase(),
            this.navigationService.getAllMenus(),
          ]);
        }
        return of([
          localUsers,
          localContract,
          localContractUsers,
          localApplicationRoles,
          localRole,
          localSpecifications,
          localClassifications,
          localContractRequirements,
          localPhases,
          { boroughNavigation, combinedBoroughs, globalNavigation },
        ]);
      }),
      map(
        ([
          users,
          contract,
          contractUsers,
          applicationRoles,
          role,
          specifications,
          classifications,
          contractRequirements,
          phases,
          root,
        ]: [
          IUser[],
          TPContract,
          TPContractUser[],
          ApplicationRole[],
          string,
          IPRFISpecification[],
          IPRFIClassification[],
          IPRFIContractRequirement[],
          Phase[],
          INavigationRoot,
        ]) => {
          const loginUser = users.find((u)=>{return u.id === this.authService.getUserId()});
          if (!users || !contract || !loginUser) {
            this.document.location.href = environment.url.portal;
          }
          const permission = this.authService.getPermission();
          this.navService.setUserItem(loginUser.givenName, loginUser.surname, loginUser.id, loginUser.mail, loginUser.companyName, permission);
          this.localContractService.currentContract = contract;
          this.localContractUserService.currentUserContractRole = role;
          const doc = contractUsers.find((cu) => {
            return cu.contract_user_application_role.find((a) => {
              return (
                a.ApplicationId === rfiAppId &&
                a.ApplicationRoleId === rfiDocRoleId
              );
            });
          });
          this.localContractUserService.defaultDoc = doc?.UserId ?? null;
          this.localContractService.setItem(contract);
          this.localApplicationRoleService.setItems(applicationRoles);
          const contractUsersWithInfo: IContractUserWithUserInfo[] =
            contractUsers
              .map((cu) => {
                const userInfo = users.find((u) => {
                  return u.id === cu.UserId;
                });
                return combineUserInfo(cu, userInfo);
              })
              .filter((cu) => {
                return cu.UserInfo;
              });
          // filter specification list by contract code before sending it to local service
          const filteredSpecifications = specifications.filter((item) => {
            const codeArr = item.ContractCode.split(",");
            return codeArr.some((code) => {
              return contract.Code.includes(code);
            });
          });

          const filteredClassification = classifications.filter((item) => {
            if (!item.ContractCode) {
              return true;
            }
            const codeArr = item.ContractCode.split(",");
            return codeArr.some((code) => {
              return contract.Code.includes(code);
            });
          });

          const sortedClassification = filteredClassification.sort(sortByName);

          // sort contractRequirement list in alphabetical order
          const sortedContractRequirements =
            contractRequirements.sort(sortByName);

          // sort phase list in alphabetical order
          const sortedPhases = phases.sort(sortByTitle);

          this.localContractUserService.setItems(contractUsersWithInfo);
          this.localUserService.setItems(users);
          this.localSpecificationService.setItems(filteredSpecifications);
          this.localClassificationService.setItems(sortedClassification);
          this.localContractRequirementService.setItems(
            sortedContractRequirements,
          );
          this.localPhaseService.setItems(sortedPhases);
          if (root) {
            this.navService.setBroughData(root.boroughNavigation);
            this.navService.setGlobalData(root.globalNavigation);
            this.navService.setDataSubject(root.combinedBoroughs);

            if (root.globalNavigation.length) {
              this.navService.setSelectedId(
                root.globalNavigation[0].globalModule.Guid,
              );
            } else if (root.boroughNavigation.length) {
              this.navService.setSelectedId(
                root.boroughNavigation[0].boroughItem.Guid,
              );

              this.localContractService.setItem(
                root.boroughNavigation[0].projects[0],
              );
            }
          }
          return;
        },
      ),
      first(),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 404) {
          this.document.location.href = environment.url.portal;
        } else {
          const localContract = this.localContractService.getItem();
          if (localContract?.Guid) {
            this.router.navigate([
              localContract.Guid,
              "error",
              error.status ? error.status : 500,
            ]);
          } else {
            this.router.navigate(["error", error.status ? error.status : 500]);
          }
        }
        return of();
      }),
    );
  }
}
