import { ModalContainerService, NotificationComponent, NotificationType } from "@aecom/core";
import { Injectable } from "@angular/core";
import { ContractUserApplicationService, RFIReviewerService, RFIItemService, IPRFIItem, IPRFIDocumentDownload, IRFIReopen, RFIDocumentsService, IPRFIDocumentUploadReturn, IPRFIReviewSubmitItem, IPRFIReviewerInit, RfiReviewer, EmailService, IRFINotificationReviewer, RfiWatcher, IPRFIWatcherInit, RFIWatcherService, IUser, IPRFIReviewerRemoveItem, IPRFIWatcherRemoveItem } from "../api-generated";
import { ActivatedRoute, Router } from "@angular/router";
import LoadingService from "./loading.service";
import LocalContractUserService from "./local-contractUser.service";
import { RecallModalComponent } from "@shared/recall-modal/recall-modal.component";
import _ from "lodash";
import { HttpErrorResponse } from "@angular/common/http";
import { DelegateModalComponent } from "@shared/delegate-modal/delegate-modal.component";
import LocalApplicationRoleService from "./local-application_role.service";
import LocalRFIItemService from "./local-rfiItem.service";
import RFIRecallRequest from "@models/rfiRecallRequest";
import RFIRecallStep from "@models/rfiRecallStep";
import RFIDelegate from "@models/rfiDelegate";
import { ReadRecallModalComponent } from "@shared/readRecall-modal/readRecall-modal.component";
import RFIUpdateBase from "@models/rfiUpdateBase";
import { SendBackModalComponent } from "@shared/sendBack-modal/sendBack-modal.component";
import RFICompilingStep from "@models/rfiCompilingStep";
import RFIRole from "@models/rfiRoles";
import RFICreateStep from "@models/rfiCreateStep";
import RFIInReviewStep from "@models/rfiInReviewStep";
import RFIRecallApprovalStep from "@models/rfiRecallApprovalStep";
import RFIReview from "@models/rfiReview";
import ContractNestedViewItem from "@models/contractNestedViewItem";
import RFIReopenApproval from "@models/rfiReopenApproval";
import { ReopenModalComponent } from "@shared/reopen-modal/reopen-modal.component";
import RFIReopenStep from "@models/rfiReopenStep";
import { v4 as uuidv4 } from 'uuid';
import { FileModel } from "@aecom/core";
import { UploadStatus } from "@aecom/core";
import RFIDocumentUpload from "@models/rfiDocumentUpload";
import RFIStatus from "@models/rfiStatus";
import AzureBlobService from "./azureBlob.service";
import { combineUserInfo, generateUserRowItem, getAllUsersExceptDB } from "@shared/utils";
import RFIDocumentCopy from "@models/rfiDocumentCopy";
import LocalUserService from "./local-user.service";
import RFIUpdateDueDateStep from "@models/rfiUpdateDueDateStep";
import { UpdateDueDateModalComponent } from "@shared/updateDueDate-modal/updateDueDate-modal.component";
import { AuthService, BBJFileListItem, BBJFileListService, BBJSelectUserService } from "@bbj/components";
import RFIFileType from "@models/RFIFileType";
import { lastValueFrom } from "rxjs";
import RFISelectUser from "@models/rfiSelectUser";
import ISelectUserCloseItem from "@bbj/components/lib/components/selectUser/ISelectUserCloseItem";


@Injectable({
  providedIn: "root",
})
export default class BaseFunctionService {
  protected entity: IPRFIItem;
  protected contractId: string;
  protected docs: IPRFIDocumentDownload[] = [];
  protected tempGuid: string;

  reviewersResponse: (RfiReviewer & {UserInfo?: IUser})[];

  watchersResponse: (RfiWatcher & {UserInfo?: IUser})[];

  protected responseView: boolean | undefined = undefined; 

  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public rfiItemService: RFIItemService,
    public rfiDocumentsService: RFIDocumentsService,
    public localContractUserService: LocalContractUserService,
    public rfiReviewerService: RFIReviewerService,
    public localApplicationRoleServices: LocalApplicationRoleService,
    public contractUserApplicationService: ContractUserApplicationService,
    public localRFIItemService: LocalRFIItemService,
    public localUserService: LocalUserService,
    public emailService: EmailService,
    private rfiWatcherService: RFIWatcherService,
    public bbjFileListService: BBJFileListService,
    public bbjSelectUserService: BBJSelectUserService
  ) {}

  setEntity(entity: IPRFIItem, docs: IPRFIDocumentDownload[]): void {
    this.entity = entity;
    this.contractId = this.entity.ContractId;
    this.docs = docs;
    this.tempGuid = undefined;
  }

  setContractId(contractId: string): void {
    this.contractId = contractId;
    this.entity = undefined;
    this.docs = [];
    this.tempGuid = undefined;
  }

  setTempId(id: string): void {
    this.tempGuid = id;
  }

  getEntity(): IPRFIItem {
    return this.entity;
  }

  getDocs(): IPRFIDocumentDownload[] {
    return this.docs;
  }

  initFileList(): void {
    if(this.entity && this.docs)
    {
      if(this.entity.Status === RFIStatus.QAQC_Fail || this.entity.Status === RFIStatus.Draft)
      {
        this.bbjFileListService.initItem([
          {
            files:this.getFileListForCurrentStep(RFIStatus.Draft),
            title:RFIFileType.Attachment
          },
        ]);
      } else {
        this.bbjFileListService.initItem([
          {
            files:this.getFileListForCurrentStep(this.entity.Status, this.tempGuid),
            title:RFIFileType.Attachment
          },
        ]);
      }
    } else {
      this.bbjFileListService.initItem([
        {files:[],title:RFIFileType.Attachment},
      ]);
    }
  }

  getBBJFileListItemAttachment(): BBJFileListItem | undefined {
    return this.bbjFileListService.getFileList(RFIFileType.Attachment);
  } 

  getFileListForCurrentStep(status: string, refId?: string): FileModel[] {
    return _.uniqBy(
      this.docs.filter((item) => {
        if(refId)
        {
          return item.ReferenceId === refId;
        }
        return item.Status === status;
      }).map((item)=>{
        return new FileModel(
          item.Guid, // use document Guid instead of fileId
          item.FileName,
          item.DateCreated,
          item.URL,
          "preloaded",
          0,
          null,
          item.DownloadURL,
        )
      }).sort((a, b) => {
        return new Date(b.Base64).getTime() - new Date(a.Base64).getTime();
      }),
      "Name"
    );
  }

  deleteFile(e: FileModel): void {
    const fileList = this.bbjFileListService.getFileList(RFIFileType.Attachment);
    fileList.deleteFile(e);
  }

  importFile(e: FileModel[]): void {
    let status = "";
    let itemId = "";
    let refId: string | undefined = undefined;
    const fileList = this.bbjFileListService.getFileList(RFIFileType.Attachment);
    if(!this.entity)
    {
      status = RFIStatus.Draft;
      itemId = this.tempGuid;
    } else {
      itemId = this.entity.Guid;
      switch(this.entity.Status) {
        case RFIStatus.QAQC_Fail:
          status = RFIStatus.Draft;
          break;
        case RFIStatus.Responded: 
        case RFIStatus.Canceled:
        case RFIStatus.Superseded:
          status = RFIStatus.Request_to_Reopen;
          break;
        default:
          status = this.entity.Status;
          break;
      }
      if(this.tempGuid)
      {
        refId = this.tempGuid;
      }
    }
    if (!_.isEmpty(e)) {
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new RFIDocumentUpload(
            item.Name,
            this.authService.getUserId(),
            status,
            itemId,
            refId
          );

          await lastValueFrom(this.rfiDocumentsService
            .createRFIDocument(fileUploaded))
            .then((r: IPRFIDocumentUploadReturn) => {
              item.Guid = r.Guid;
              fileList.updateFileList(item);
              AzureBlobService.uploadFile(r.URL, item);
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          fileList.deleteFile(item);
        }
      });
    }
  }

  addFileToAttachment(prevItem: FileModel): void {
    const item = JSON.parse(JSON.stringify(prevItem));
    item.Percentage = 100;
    item.Status = "preloaded";

    let refId: string | undefined = undefined;
    const fileList = this.bbjFileListService.getFileList(RFIFileType.Attachment);
    if(this.tempGuid)
    {
      refId = this.tempGuid;
    }
    if (!fileList.isFileExist(prevItem)) {
      const fileUploaded = new RFIDocumentCopy(
        this.entity.Status,
        this.entity.Guid,
        item.Guid,
        refId
      );

      this.rfiDocumentsService
        .copyRFIDocument(fileUploaded)
        .subscribe((res) => {
          item.Guid = res.Guid;
          fileList.updateFileList(item);
        });
    }
  }

  initUsers(readonly?: boolean): void {
    const users = this.localUserService.getItems();
    const contractUsers = this.localContractUserService.getItems();
    const all = getAllUsersExceptDB(contractUsers);
    const initReviewer = this.entity.rfi_reviewer.map((item) => {
      const user = users.find((u) => {
        return u.id === item.ReviewerId;
      });
      return generateUserRowItem(combineUserInfo(item, user));
    });

    const initWatcher = this.entity.rfi_watcher.map((item) => {
      const user = users.find((u) => {
        return u.id === item.WatcherId;
      });
      return generateUserRowItem(combineUserInfo(item, user));
    });
    this.reviewersResponse = this.entity.rfi_reviewer.map((item) => {
      const user = users.find((u)=>{return u.id === item.ReviewerId});
      return combineUserInfo<RfiReviewer>(item, user);
    });
    this.watchersResponse = this.entity.rfi_watcher.map((item) => {
      const user = users.find((u)=>{return u.id === item.WatcherId});
      return combineUserInfo<RfiWatcher>(item, user);
    });
    this.bbjSelectUserService.initItem([
      {title: RFISelectUser.ReviewerTitle, all, initData: initReviewer, btnText: RFISelectUser.ReviewerBtn, windowTitle: RFISelectUser.ReviewerWindowTitle, readonly},
      {title: RFISelectUser.ObserverTitle, all, initData: initWatcher, btnText: RFISelectUser.ObserverBtn, windowTitle: RFISelectUser.ObserverWindowtitle, readonly}
    ]);
  }

  errorHandle(error: HttpErrorResponse, activeModal: ModalContainerService, router: Router): void {
    if (error.status === 403 || error.status === 409) {
      const modalInstance1 = activeModal.open(NotificationComponent);
      modalInstance1.instance.type = NotificationType.Information;
      modalInstance1.instance.theme = "light";
      modalInstance1.instance.title = "Your Changes Can't be Saved";
      modalInstance1.instance.body = "Someone already saved changes. Please refresh your screen to see updates.";
      modalInstance1.result.then((result1) => {
        if (result1 === 0) {
          const currentUrl = router.url;
          router.navigateByUrl("blank").then(() => {
            router.navigateByUrl(currentUrl);
          });
        }
      });
    } else {
      console.log(error);
      const modalInstance = activeModal.open(NotificationComponent);
      modalInstance.instance.type = NotificationType.Information;
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Error";
      modalInstance.instance.body = "An unexpected error occurred";
    }
  }

  back(): void {
    this.router.navigateByUrl(`${this.contractId}/list`);
  }

  backAfterConfirm(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Cancel editing?";
    modalInstance.instance.body = "Your changes will not be saved.";

    modalInstance.result.then((result) => {
      if (result === 1) {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      }
    });
  }

  recallRFI(): void {
    const modalInstance = this.activeModal.open(RecallModalComponent);

    modalInstance.result.then((result: RFIRecallRequest) => {
      console.log("result", result);

      if (
        (result && result.comments && !(_.isNumber(result.comments) && result.comments === 0)) ||
        _.isString(result.comments)
      ) {
        this.loadingService.start();
        const request = new RFIRecallStep(this.contractId, result, this.entity);

        this.rfiItemService.rfiUpdate(request).subscribe(
          () => {
            this.loadingService.stop();
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop();
            this.errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  reviseRFI(): void {
    this.router.navigateByUrl(`${this.contractId}/create/${this.entity.Guid}`);
  }

  reassign(): void {
    const modalInstance = this.activeModal.open(DelegateModalComponent);
    modalInstance.instance.rfiItem = this.entity;

    modalInstance.result.then((result: RFIDelegate | null) => {
      console.log("result", result);

      if (result) {
        this.loadingService.start();
        this.rfiItemService.rfiDelegate(result).subscribe(
          () => {
            this.loadingService.stop(true);
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop(true);
            this.errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  dbReadReject(): void {
    const modalInstance = this.activeModal.open(ReadRecallModalComponent);

    modalInstance.instance.title = "Recalled RFI has been Rejected";
    modalInstance.instance.body =
      "Doc Control rejected your recall request. Please contact directly to the Doc Control for any question.";

    modalInstance.result.then((result) => {
      if (result === 1) {
        const item = new RFIUpdateBase(
          this.contractId,
          this.entity,
        );

        this.rfiItemService.dbReadRejectedRecallRFI(item).subscribe();
      }
    });
  }

  dbViewRFI(): void {
    const item = new RFIUpdateBase(this.contractId, this.entity);

    this.rfiItemService.viewedRFI(item).subscribe(
      (r) => {
        console.log(r);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
    );
  }

  dbVoidRFI(): void {
    if (this.bbjFileListService.hasFilesUploading()) {
      this.bbjFileListService.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Void RFI?";
      modalInstance.instance.body = "Your RFI will be voided.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          const item = new RFIUpdateBase(
            this.contractId,
            this.entity,
          );
          item.IsDraft = false;
          this.rfiItemService.voidRFI(item).subscribe(
            () => {
              this.loadingService.stop(true);
              this.router.navigateByUrl(`${this.contractId}/list`);
            },
            (error: HttpErrorResponse) => {
              this.loadingService.stop(true);
              this.errorHandle(error, this.activeModal, this.router);
            },
          );
        }
      });
    }
  }

  OpenAlert(body: string, title = "Error") {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = title;
    modalInstance.instance.body = body;
    modalInstance.result;
  }

  sendBack(): void {
    const modalInstance = this.activeModal.open(SendBackModalComponent);
    modalInstance.instance.by = this.localApplicationRoleServices.getRoleById(this.entity.BallInCourt);
    modalInstance.result.then((result) => {
      if (result && result !== 0 && result.length > 0) {
        this.loadingService.start();
        const releaseDisposition = new RFICompilingStep(this.contractId, this.entity);
        releaseDisposition.IsDraft = false;
        releaseDisposition.ToDDC = false;
        releaseDisposition.ToSrManager = false;
        releaseDisposition.rfi_compiled_response.Response = result;
        releaseDisposition.rfi_compiled_response.IsDraft = false;
        releaseDisposition.rfi_compiled_response.ScheduleImpact = undefined;
        releaseDisposition.rfi_compiled_response.CostImpact = undefined;
        releaseDisposition.rfi_compiled_response.To = RFIRole.Coordinator;
        this.rfiItemService.rfiUpdate(releaseDisposition).subscribe(
          () => {
            this.loadingService.stop(true);
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop(true);
            this.errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  update(rfi: RFICreateStep | RFIInReviewStep | RFICompilingStep | RFIRecallStep | RFIRecallApprovalStep | RFIUpdateBase) {
    this.bbjFileListService.updateFiles(rfi, rfi["DosePMCAccept"] === false);
    this.rfiItemService.rfiUpdate(rfi).subscribe(
      () => {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.loadingService.stop(true);
        this.errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  reviewerSubmit(item: IPRFIReviewSubmitItem): void {
    this.loadingService.start();
    if(!item.hasComments)
    {
      item.Response = "";
    }
    this.bbjFileListService.updateFiles(item,!item.hasComments);
    this.rfiReviewerService.submitRFIReview(item).subscribe(
      () => {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.loadingService.stop(true);
        this.errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  delegate(rfi: RFIDelegate) {
    this.rfiItemService.rfiDelegate(rfi).subscribe(
      () => {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.loadingService.stop(true);
        this.errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  submitMyReviewResponse(review: RFIReview) {
    this.rfiReviewerService
      .submitRFIReview(review)
      .subscribe(
        () => {
          this.loadingService.stop(true);
          this.router.navigateByUrl(`${this.contractId}/list`);
        },
        (error: HttpErrorResponse) => {
          this.loadingService.stop(true);
          this.errorHandle(error, this.activeModal, this.router);
        },
      );
  }

  subDelegate(item: RFIDelegate): void {
    this.rfiItemService.rfiDelegate(item).subscribe(
      () => {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.loadingService.stop(true);
        this.errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  getNestedViewFlag(): boolean {
    return this.localRFIItemService.nestedView;
  }

  setNestedViewFlag(value: boolean): void {
    this.loadingService.start();
    const nestedViewItem = new ContractNestedViewItem(this.authService.getUserId(), this.contractId, value);
    this.contractUserApplicationService.updateApplicationNestedVeiw(nestedViewItem).subscribe(
      () => {
        this.localRFIItemService.nestedView = value;
        this.loadingService.stop();
      },
      (error: HttpErrorResponse) => {
        this.loadingService.stop();
        this.errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  rfiReopen(): void {
    const item = new RFIReopenStep(this.contractId, this.entity);
    item.tempId = uuidv4();
    this.initFileList();
    this.setTempId(item.tempId);
    const modalInstance = this.activeModal.open(ReopenModalComponent);
    modalInstance.instance.item = item;
    modalInstance.result.then((result) => {
      if (result) {
        console.log(result);
        this.loadingService.start();
        this.rfiItemService.rfiReopen(result).subscribe(
          () => {
            this.loadingService.stop(true);
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop(true);
            this.errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  rfiReopenAcknowledge(): void {
    if(this.entity.rfi_reopen?.length > 0)
    {
      const reopen = this.entity.rfi_reopen.sort((a, b) =>
        new Date(b.DateRequest).getTime() - new Date(a.DateRequest).getTime(),
      )[0];
      const rfi = new RFIReopenApproval(this.contractId, this.entity, reopen);
      this.rfiItemService.rfiReopenApproval(rfi).subscribe({
        complete: () => {
          this.loadingService.stop(true);
          this.router.navigateByUrl(`${this.contractId}/list`);
        },
        error: (error: HttpErrorResponse) => {
          this.loadingService.stop(true);
          this.errorHandle(error, this.activeModal, this.router);
        },
      });
    }
  }

  // rfiReopenApproval(approval: boolean, reason?: string | null): void {
  //   if(!approval)
  //   {
  //     const modalInstance = this.activeModal.open(NotificationComponent);
  //     modalInstance.instance.theme = "light";
  //     modalInstance.instance.title = "Submit Response?";
  //     modalInstance.instance.body = "<span class='warningFont'>Upon submission, DDC/AHJV reserves the right to proceed with the appropriate measures to respond and resolve issues in accordance with the Agreement.</span>";

  //     modalInstance.result.then((result) => {
  //       if (result === 1) {
  //         this.rfiReopenApprovalAction(approval, reason);
  //       }
  //     });
  //   }
  //   else{
  //     this.rfiReopenApprovalAction(approval, reason);
  //   }
  // }

  readReopened(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "RFI has been reopened";
    modalInstance.instance.showCancel = false;
    modalInstance.instance.body = "<span class='warningFont'>Please send back to Coordinator OR modify and reissue the response.</span>";

    modalInstance.result.then((result) => {
      if (result === 1) {
        const item = new RFIUpdateBase(
          this.contractId,
          this.entity,
        );

        this.rfiItemService.viewedRFI(item).subscribe();
      }
    });
  }

  updateDueDate(): void {
    const item = new RFIUpdateDueDateStep(this.contractId, this.entity);
    const modalInstance = this.activeModal.open(UpdateDueDateModalComponent);
    modalInstance.instance.dueDateItem = item;
    modalInstance.result.then((result) => {
      if (result) {
        console.log(result);
        this.loadingService.start();
        this.rfiItemService.rfiUpdateDueDate(result).subscribe({
          complete: () => {
            this.loadingService.stop(true);
            location.reload();
            //this.router.navigateByUrl(`${this.contractId}/list`);
          },
          error: (error: HttpErrorResponse) => {
            this.loadingService.stop(true);
            this.errorHandle(error, this.activeModal, this.router);
          },
        });
      }
    });
  }

  isInputInvalid(str: string | null | undefined): boolean {
    if(str)
    {
      const invalidChars = /[^\x20-\x7E\n\r]+/;
      return invalidChars.test(str);
    }
    return false;
  }

  openSelectUserWindow(isReviewer: boolean): void {
    this.bbjSelectUserService.setWorkingItem(isReviewer? RFISelectUser.ReviewerTitle : RFISelectUser.ObserverTitle);
  }

  closeWindowDirectSaveToDB({title, rows}: ISelectUserCloseItem): void {
    if (rows.length > 0) {
      this.loadingService.start();
      const users = this.localUserService.getItems();
      if (title===RFISelectUser.ReviewerWindowTitle) {
        this.rfiReviewerService
          .addRFIReviewer({RFIGuid: this.entity.Guid, ReviewerIds: rows.map((user)=>user.id)})
          .subscribe((res: RfiReviewer[]) => {
            this.reviewersResponse = this.reviewersResponse.concat(
              res.map((item) => {
                const user = users.find((u)=>{return u.id === item.ReviewerId});
                return combineUserInfo<RfiReviewer>(item, user);
              })
            );
            this.loadingService.stop();
        });
      } else {
        this.rfiWatcherService
          .addRFIWatcher({RFIGuid: this.entity.Guid, WatcherIds: rows.map((user)=>user.id)})
          .subscribe((res: RfiWatcher[]) => {
            this.watchersResponse = this.watchersResponse.concat(
              res.map((item) => {
                const user = users.find((u)=>{return u.id === item.WatcherId});
                return combineUserInfo<RfiWatcher>(item, user);
              })
            );
            this.loadingService.stop();
        });
      }
    }
  }

  removeUserClickDirectSaveToDB(isReviewer: boolean, userId: string): void {
    if (isReviewer) {
      const reviewerIndex: number = this.reviewersResponse.findIndex(
        (user) => user.UserInfo?.id === userId
      );

      if (reviewerIndex >= 0) {
        this.reviewersResponse.splice(reviewerIndex, 1);
        this.reviewersResponse = this.reviewersResponse.slice();

        const temp = {} as IPRFIReviewerRemoveItem;
        temp.RFIGuid = this.entity.Guid;
        temp.ReviewerId = userId;

        this.rfiReviewerService.removeRFIReviewer(temp).subscribe((res) => {
          console.log("res", res);
        });
      }
    } else {
      const watcherIndex = this.watchersResponse.findIndex(
        (user) => user.UserInfo?.id === userId
      );

      if (watcherIndex >= 0) {
        this.watchersResponse.splice(watcherIndex, 1);
        this.watchersResponse = this.watchersResponse.slice();

        const temp = {} as IPRFIWatcherRemoveItem;
        temp.RFIGuid = this.entity.Guid;
        temp.WatcherId = userId;

        this.rfiWatcherService.removeRFIWatcher(temp).subscribe((res) => {
          // console.log("res", res);
        });
      }
    }
  }
}

