/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable class-methods-use-this */
import { Injectable } from "@angular/core";
import { AuthService } from "@bbj/components";
import LoadingService from "@services/loading.service";
import { Observable } from "rxjs";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: "root",
})
export class PendingChangesGuard {
  constructor(private authService: AuthService, public loadingService: LoadingService) {}

  canDeactivate(
    component: ComponentCanDeactivate,
  ): boolean | Observable<boolean> {
    return this.authService.isTokenExpired() ? confirm(  "WARNING: Your session is expired, click Ok to refresh your page.")
      : this.loadingService.turnOffCanDeactive === true ? true : component.canDeactivate()
      ? true
      : confirm(
          "WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.",
        );
  }

}
