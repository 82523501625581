<div class="contentcontainer">
  <div class="searchBox" *ngIf="showSearch">
    <aecom-textbox
      [theme]="'light'"
      [(ngValue)]="searchText"
      placeholder="Search"
      iconLeft="icon-basic_magnifier"
      (ngValueChange)="updateData()"
    ></aecom-textbox>
  </div>

  <div class="contentBox">
    <ng-container *ngFor="let item of result">
      <div class="responseBox">
        <div class="responseHeader">
          <span>{{ item.ReviewerName }}</span>

          <span *ngIf="item.ReviewerOrganization && item.ReviewerOrganization !== ''">
            <span class="responseHeaderSeparate">|</span>{{ item.ReviewerOrganization }}
          </span>

          <span *ngIf="item.ReviewerTrackName && item.ReviewerTrackName !== ''">
            <span class="responseHeaderSeparate">|</span>{{ item.ReviewerTrackName }}
          </span>

          <div class="responseHeaderDate">{{ item.DateUpdated }}</div>

          <div class="responseHeaderCollapse" (click)="item.Collapse = !item.Collapse">
            <i
              [ngClass]="item.Collapse ? 'icon-action_arrow_down' : 'icon-action_arrow_up'"
              class="icon icon-blue icon-lg"
            ></i>
          </div>
        </div>

        <div class="responseContent" *ngIf="!item.Collapse">
          <div class="responseBody" *ngIf="!item.HasComments">
            <div class="responseBodySubContent">
              <div class="dispositionText">No Comments</div>
            </div>
          </div>
          <div class="responseBody" *ngIf="item.HasComments" [innerHtml]="item.Response"></div>
          <div class="responseBody" *ngIf="item.HasComments  && item.Attachments && item.Attachments.length > 0">
            <bbj-file-list
              [fileList]="item.Attachments"
              title="Attachments"
              [allowMovingFiles]="allowMovingFiles"
              (movedFile)="attachmentOut($event)"
              (movedFiles)="attachmentsOut($event)"
            ></bbj-file-list>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="contentBox">
    <div class="contentTitle">{{ isReviewer ? "Pending Reviewer" : "Observer" }} list</div>

    <ng-container *ngFor="let item of unsubmittedResponses">
      <div class="pendingBox">
        <div class="pendingHeader">
          <span>{{ item.ReviewerName }}</span>

          <span *ngIf="item.ReviewerOrganization && item.ReviewerOrganization !== ''">
            <span class="responseHeaderSeparate">|</span>{{ item.ReviewerOrganization }}
          </span>

          <span *ngIf="item.ReviewerTrackName && item.ReviewerTrackName !== ''">
            <span class="responseHeaderSeparate">|</span>{{ item.ReviewerTrackName }}
          </span>

          <div class="actions-wrapper">
            <div *ngIf="showAddUserIcon" class="responseNotify" (click)="removeUserClick(item)">
              <i class="icon icon-social_user_remove icon-orange icon-lg" style="margin-right: 8px"></i>
              <span>Remove</span>
            </div>

            <div *ngIf="turnOnNotifyUser" class="responseNotify" (click)="notifyUser(item)">
              <i class="icon icon-notify icon-green icon-lg" style="margin-right: 8px"></i>
              <span>Notify User</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div
      style="width: 100%; height: 100%; display: flex; align-items: center"
      *ngIf="unsubmittedResponses.length === 0"
    >
      <div class="noItem-body">
        <div>
          <div class="noItem-icon-row icon icon-social_user_team_big icon-grey icon-xl"></div>
        </div>
        <div class="noItem-title-row">None</div>
      </div>
    </div>

    <div *ngIf="showAddUserIcon" style="position: absolute; top: 22px; right: 0; cursor: pointer">
      <div class="formGroupManu" (click)="openWindowClick()">+ Add {{ isReviewer ? "Reviewers" : "Observers" }}</div>
    </div>
  </div>
</div>
