/* eslint-disable prettier/prettier */
import { FileModel, FilterPipe, ModalContainerService, NotificationComponent, NotificationType } from "@aecom/core";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import BaseFunctionService from "@services/baseFunction.service";
import { RfiReviewer , RfiWatcher , IPRFIDocumentDownload , IUser , EmailService, IRFINotificationReviewer } from "src/app/api-generated";
import RFIResponseDetail from "src/app/models/rfiResponseDetail";
import RFIRole from "src/app/models/rfiRoles";

@Component({
  selector: "rfiResponse-detail",
  templateUrl: "./rfiResponse-detail.component.html",
  styleUrls: ["./rfiResponse-detail.component.scss"]
})
export default class RFIResponseDetailComponent implements OnInit, OnChanges {
  @Input() rfiReviews: ((RfiReviewer | RfiWatcher) & {UserInfo?: IUser})[];

  @Input() showSearch = false;

  @Input() turnOnNotifyUser = false;

  @Input() docs: IPRFIDocumentDownload[] = [];

  @Input() showAddUserIcon = false;

  @Input() isReviewer = false;

  @Input() allowMovingFiles = false;

  @Output() moveAttachment = new EventEmitter<FileModel>();

  @Output() moveAttachments = new EventEmitter<FileModel[]>();

  private submittedResponses: RFIResponseDetail[];

  public unsubmittedResponses: RFIResponseDetail[];

  public result: RFIResponseDetail[];

  public searchText = "";

  getFileIcon = RFIResponseDetailComponent.getFileIcon;

  constructor(private emailService: EmailService, public activeModal: ModalContainerService, public baseFunctionService: BaseFunctionService,) {}

  ngOnInit(): void {
    this.updateView();
  }

  updateView(): void {
    const responses = this.rfiReviews.map((item) => {
      return new RFIResponseDetail(item, this.docs);
    });

    this.submittedResponses = responses.filter((item) => {
      return (item.IsDraft === false && item.Response !== "") || item.HasComments === false;
    });

    this.unsubmittedResponses = responses.filter((item) => {
      return item.HasComments === undefined || (item.HasComments === true && (item.IsDraft === true || item.Response === ""));
    });

    this.result = [...this.submittedResponses];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.rfiReviews = changes.rfiReviews?.currentValue;
    this.updateView();
  }

  updateData(): void {
    // console.log(this.submittedResponses);
    this.result = FilterPipe.filter(this.submittedResponses, this.searchText);
  }

  static getFileIcon(type: string): string {
    return type.includes("image") ? " icon icon-image_doc" : " icon icon-blank_doc";
  }

  notifyUser(item: RFIResponseDetail): void {
    const data: IRFINotificationReviewer = {
      rfiGuid: item.RFIGuid,
      reviewerIds: [item.UserId],
      role: this.isReviewer ? RFIRole.Reviewer : RFIRole.Watcher
    };

    this.emailService.notifyRFIReviewerAndWatcher(data).subscribe();
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Notification Confirmed";
    modalInstance.instance.body = 'The user has been notified via email to provide comments.';
    modalInstance.result;
  }

  attachmentOut(item: FileModel): void {
    this.moveAttachment.emit(item);
  }

  attachmentsOut(items: FileModel[]): void {
    this.moveAttachments.emit(items);
  }

  openWindowClick(): void {
    this.baseFunctionService.openSelectUserWindow(this.isReviewer);
  }

  removeUserClick(item: RFIResponseDetail): void {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = this.isReviewer ? "Remove Reviewer(s)" : "Remove Observer(s)";
    modalInstance.instance.body = `
    Once you click “Confirm”, selected users will be removed from the RFI as reviewers. Then if you are done removing reviewers, you may click “Back” button to return to RFI list.
    `;

    modalInstance.result.then((result) => {
      if (result === 1) {
        this.baseFunctionService.removeUserClickDirectSaveToDB(this.isReviewer, item.UserId)
      }
    });
  }
}
