import { IPRFIItem, IUser } from "../api-generated";
import {
  getDateFormatted_MMDDYYYY,
  getUserNameCompanyDepartment,
  prepareMutiLineText,
} from "../shared/utils";
import RFIStatus from "./rfiStatus";

export default class RFIDetail {
  public issuedDate: string;

  public originator: string;

  public pmcDocControl: string;

  public pmcManager: string;

  public srManager: string;

  public ddcManager: string;

  public coordinator: string;

  public title: string;

  public question: string;

  public proposedSolution: string;

  public anticipatedNextStep: string;

  public drawingAndDetailRef: string;

  public dueDate: string;

  public division: string;

  public specification: string;

  public classification: string;

  public contractRequirement: string;

  public phase: string;

  public anticipatedScheduleImpact: string;

  public scheduleImpactDetails: string;

  public anticipatedCostImpact: string;

  public costImpactDetails: string;

  public processingNotes: string;

  public masterSpec: string;

  public note: string;

  public referenceURL: string;

  public contractorReference: string;

  public internalDueDate: string;

  public respondedDate: string;

  public initRespondedDate: string | null;

  public originatorEmail: string;

  public pmcDocControlEmail: string;

  public pmcManagerEmail: string;

  public coordinatorEmail: string;

  public rfiNumber: string;

  public responder: IUser | null;

  public issuedBy: string | null;

  public officialResponse: string | null;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  constructor(rfi: IPRFIItem, users: IUser[]) {
    this.issuedDate = rfi.IssueDate
      ? this.getPrintDate(rfi.IssueDate)
      : "-";
    // determine if respondedDate should be set based on status
    if(rfi.Status === RFIStatus.Responded || rfi.Status === RFIStatus.Request_to_Reopen)
    {
      const flows = rfi.rfi_flow.sort((a,b)=>(b.DateReceived?new Date(b.DateReceived).getTime():0)-(a.DateReceived?new Date(a.DateReceived).getTime():0));
      const respondStep = flows.find((f)=>{return f.Status===RFIStatus.Responded});
      this.respondedDate =
        respondStep
          ? this.getPrintDate(respondStep.DateReceived)
          : this.getPrintDate(rfi.DateUpdated);
      this.responder = respondStep ? users.find((u) => u.id === respondStep.SubmittedBy): users.find((u) => u.id === rfi.UpdatedBy); // last updater when the RFI status = "Responded"
    }
    else
    {
      this.respondedDate = "-";
      this.responder = null;
    }
    
    this.initRespondedDate = this.getInitialResponseDate(rfi);
    const originator = users.find((u) => {
      return u.id === (rfi.IssueBy ?? rfi.CreatedBy);
    });

    const doc = users.find((u) => {
      return u.id === rfi.DocControlId;
    });

    const manager = users.find((u) => {
      return u.id === rfi.ManagerId;
    });

    const srmanager = users.find((u) => {
      return u.id === rfi.SrManagerId;
    });

    const ddcmanager = users.find((u) => {
      return u.id === rfi.DDCManagerId;
    });

    const coor = users.find((u) => {
      return u.id === rfi.CoordinatorId;
    });

    this.originator = originator?.displayName ?? "-";

    this.pmcDocControl = doc?.displayName ?? "-";

    this.pmcManager = manager?.displayName ?? "-";

    this.srManager = srmanager?.displayName ?? "-";

    this.ddcManager = ddcmanager?.displayName ?? "-";

    this.originatorEmail = originator?.mail ?? "-";

    this.pmcDocControlEmail = doc?.mail ?? "-";

    this.pmcManagerEmail = manager?.mail ?? "-";

    this.coordinator = coor?.displayName ?? "-";

    this.coordinatorEmail = coor?.mail ?? "-";

    this.title = rfi.Title ?? "";

    this.question = prepareMutiLineText(rfi.Question);

    this.proposedSolution = prepareMutiLineText(rfi.ProposedSolution);

    this.anticipatedNextStep = prepareMutiLineText(rfi.AnticipatedNextSteps);

    this.drawingAndDetailRef = rfi.DrawingDetailRef ?? "";

    this.scheduleImpactDetails = (rfi.AnticipatedScheduleImpact && rfi.ScheduleImpactDetails) ? prepareMutiLineText(rfi.ScheduleImpactDetails) : "N/A";

    this.costImpactDetails = (rfi.AnticipatedCostImpact && rfi.CostImpactDetails) ? prepareMutiLineText(rfi.CostImpactDetails) : "N/A";

    this.dueDate = rfi.DueDate ? getDateFormatted_MMDDYYYY(rfi.DueDate) : "-";

    this.internalDueDate = rfi.InternalDueDate
      ? getDateFormatted_MMDDYYYY(rfi.InternalDueDate)
      : "Not Assigned";

    this.division = rfi.rfi_specification?.Division;

    this.specification = rfi.rfi_specification
      ? `${rfi.rfi_specification.SpecId} - ${rfi.rfi_specification.Title}`
      : "N/A";

    this.classification = rfi.rfi_classification?.Name;

    this.contractRequirement = rfi.rfi_contract_requirement?.Name;

    this.phase = rfi.phase?.Title;

    this.anticipatedScheduleImpact =
      rfi.AnticipatedScheduleImpact !== null
        ? rfi.AnticipatedScheduleImpact === true
          ? "Yes"
          : "No"
        : "N/A";

    this.anticipatedCostImpact =
      rfi.AnticipatedCostImpact !== null
        ? rfi.AnticipatedCostImpact === true
          ? "Yes"
          : "No"
        : "N/A";

    this.processingNotes = rfi.ProcessingNotes
      ? prepareMutiLineText(rfi.ProcessingNotes)
      : "N/A";

    this.masterSpec =
      rfi.rfi_specification_rfi_item_MasterSpecIdTorfi_specification
        ? `${rfi.rfi_specification_rfi_item_MasterSpecIdTorfi_specification.SpecId} - ${rfi.rfi_specification_rfi_item_MasterSpecIdTorfi_specification.Title}`
        : "-";

    this.note = rfi.PriorityReason
      ? prepareMutiLineText(rfi.PriorityReason)
      : "N/A";

    this.referenceURL = rfi.ReferenceURL ?? "N/A";

    this.contractorReference = rfi.ContractorReference ?? "N/A";

    this.rfiNumber = rfi.RFINumber ?? "";

    this.issuedBy = getUserNameCompanyDepartment(originator);

    this.officialResponse = rfi.OfficialResponse?? '';
  }

  private getInitialResponseDate(item: IPRFIItem): string | null {
    const flows = item.rfi_flow.sort((a,b)=>(a.DateReceived?new Date(a.DateReceived).getTime():0)-(b.DateReceived?new Date(b.DateReceived).getTime():0));
    const reopenIndex = flows.findIndex((f,i,a)=>{
      return f.Status===RFIStatus.Request_to_Reopen && 
        a[i-1] && a[i-1].Status === RFIStatus.Responded
    });
    if(reopenIndex > 0)
    {
      const initResponseDate = flows[reopenIndex-1].DateReceived;
      return initResponseDate ? this.getPrintDate(initResponseDate) : null;
    }
    return null;
  }

  private getPrintDate(date: string): string {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
}
