<aecom-modal data-cy="modal" title="Update Due Dates?" class="modal-sm" [canClose]="false" theme="light">
  <div class="modal-body">
    <aecom-form-group columnSpan="12" columns="1" class="hideHeader">
      <aecom-form-item [label]="'Internal Due Date'" columnSpan="1" [required]="true">
        <aecom-date
          [weekendsOff]="true"
          [theme]="'light'"
          [ngValue]="internalDueDate"
          (change)="setDate($event)"
          [notValidAfter]="maxInternalDueDate"
          [validate]="!internalDueDate"
        ></aecom-date>
      </aecom-form-item>
      
      <aecom-form-item [label]="'Response Due Date'" columnSpan="1" [required]="true">
        <aecom-date
          [weekendsOff]="true"
          [theme]="'light'"
          [ngValue]="dueDate"
          [notValidBefore]="minDueDate"
          (change)="setDueDate($event)"
          [validate]="!dueDate"
        ></aecom-date>
      </aecom-form-item>
    </aecom-form-group>
  </div>

  <div class="modal-footer">
    <div></div>

    <div>
      <aecom-button innerClass="orange lg outline" (click)="cancel()">Cancel</aecom-button>

      <aecom-button innerClass="blue lg solid" [disabled]="!canIssue() ? true : null" (click)="canIssue() && save()">Confirm</aecom-button>
    </div>
  </div>
</aecom-modal>
