<aecom-layout *ngIf="isLoaded">
  <aecom-header
    *ngIf="navService.user$ | async"
    [productname]="productname"
    (homeClick)="goToHome()"
    [productIcon]="'rfi_icon'"
    [productsubtitle]="productsubtitle"
    [mainTitleIcon]="productmainicon"
    [productmaintitle]="productmaintitle"
    [user]="navService.user$ | async"
    [(menuVisible)]="layoutService.showLeftNav"
    [logoutAction]="authService.logout.bind(authService)"
    [theme]="'dark'"
    [appLogo]="'appLogo'"
    [showAppMenu]="true"
    [appItems]="appItems"
  >
    <bbj-action-list
      *ngIf="!isDBAdmin"
      [data]="taskList"
      class="app-header-action-bar"
    ></bbj-action-list>
  </aecom-header>

  <aecom-left-nav
    id="sidebarwrapper"
    [menuItems]="[]"
    [isVisible]="false"
    [showSearch]="false"
    [theme]="'dark'"
    [copyright]="copyright"
  >
  <div *ngIf="isBoroughMenu" style="width: 100%; height: 100%; position: relative; display: flex; flex-flow: column">
    <div>
      <aecom-form-item [label]="'Borough'">
        <aecom-dropdown
          #boroughDropDown
          name="boroughDropdown"
          [theme]="'dark'"
          [placeholder]="'Select Borough'"
          [data]="boroughSelectionList"
          (ngValueChange)="onBoroughSelected($event)"
          [selectedId]="boroughSelectionId"
        ></aecom-dropdown>
      </aecom-form-item>
    </div>

    <div style="padding-top: 15px">
      <aecom-form-item [label]="'Contract'">
        <aecom-dropdown
          #contractDropDown
          name="contractDropdown"
          [theme]="'dark'"
          [placeholder]="'Select Contract'"
          [data]="contractSelectionList"
          (ngValueChange)="onContractSelected($event)"
          [selectedId]="contractSelectionId"
        ></aecom-dropdown>
      </aecom-form-item>
    </div>

    <div style="margin-top: 16px;" *ngIf="leftNav.length">
      <aecom-browser
        class="browser nav-browser"
        [nodes]="leftNav"
        [setRootNode]="true"
        [showSearch]="false"
        innerClass="lg text-highlight"
        (ngValueChange)="navigate($event)"
      ></aecom-browser>
    </div>

    <hr [ngStyle]="{ 'margin-top': leftNav.length ? '16px' : '32px' }" />

    <div style="margin-top: 16px; overflow: auto;">
      <aecom-browser
        class="browser nav-browser"
        [nodes]="globalNav"
        [setRootNode]="true"
        [showSearch]="false"
        innerClass="lg text-highlight"
        (ngValueChange)="navigate($event)"
      ></aecom-browser>
    </div>

    <div
      style="width: 100%;"
    >
      <div *ngFor="let nav of bottomNav" style="width: 100%; margin: 8pt">
        <aecom-button
          style="width: 100%"
          id="{{ nav.id }}"
          innerClass="btn-icon blue solid"
          icon="{{ nav.externalicon }} icon icon-black icon-basic_home"
          (click)="navigate(nav)"
          ><div style="width: 140pt">{{ nav.name }}</div></aecom-button
        >
      </div>
    </div>
  </div>
  </aecom-left-nav>
</aecom-layout>
