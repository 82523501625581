<div class="maincontainer" *ngIf="rfi">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel [defaultTab]="defaultTab"></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button
                [theme]="'light'"
                innerClass="btn lg outline orange"
                (click)="back()"
              >
                BACK
              </aecom-button>
              
              <aecom-button
                *ngIf="showReassignBtn"
                innerClass="btn lg outline blue"
                (click)="reassign()"
                data-cy="reassign"
              >
                Reassign
              </aecom-button>

              <ng-container *ngIf="showPrint">
                <aecom-button
                  innerClass="btn lg solid blue"
                  (click)="generateReport()"
                >
                  DOWNLOAD AS PDF
                </aecom-button>
              </ng-container>

              <aecom-button
                [theme]="'light'"
                *ngIf="IsReject && (IsDbAdmin || IsDocControl)"
                innerClass="btn lg outline orange"
                (click)="voidRFI()"
              >
                Void
              </aecom-button>

              <aecom-button
                *ngIf="IsReject && (IsDbAdmin || IsDocControl)"
                innerClass="btn lg solid blue"
                (click)="reviseRFI()"
              >
                Revise
              </aecom-button>

              <aecom-button
                *ngIf="showRecallButton"
                data-testid="requestCancelBtn"
                innerClass="btn lg outline blue"
                (click)="recallRFI()"
              >
                Request to Cancel
              </aecom-button>

              <aecom-button
                [theme]="'light'"
                *ngIf="isRecalled && IsDocControl && rfi.Status !== 'Canceled'"
                innerClass="btn lg solid blue"
                (click)="submitRFI()"
              >
                Submit
              </aecom-button>
              <aecom-button
                *ngIf="showReopenBtn"
                innerClass="btn lg outline blue"
                (click)="reopen()"
              >
                Reopen
              </aecom-button>
              <!-- <aecom-button
                *ngIf="showReopenAcknowledge"
                [innerClass]="acknowledge?'btn lg solid blue':'btn lg outline blue'"
                (click)="reopenApproval()"
              >
                Submit
              </aecom-button> -->
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isRecalled">
        <aecom-form-group
          columnSpan="6"
          columns="2"
          [title]="
            IsDocControl && rfi.Status != 'Canceled'
              ? 'Recall Request'
              : 'Recall Reason'
          "
        >
          <aecom-form-item
            *ngIf="isRecalled"
            [label]="'Note from Design Builder'"
            columnSpan="2"
          >
            <div class="button-wrapper d-flex" [innerHtml]="note">
            </div>
          </aecom-form-item>

          <aecom-form-item
            *ngIf="IsDocControl && rfi.Status != 'Canceled'"
            [label]="'Do you accept Recall?'"
            columnSpan="1"
          >
            <div class="button-wrapper d-flex">
              <aecom-button
                innerClass="btn solid btn-tab {{
                  !response.ShowRecallRejected ? 'blue' : 'bg-grey-e'
                }}"
                (click)="setAcceptRecall(false)"
              >
                Yes
              </aecom-button>

              <aecom-button
                innerClass="btn solid btn-tab {{
                  response.ShowRecallRejected ? 'blue' : 'bg-grey-e'
                }}"
                (click)="setAcceptRecall(true)"
              >
                No
              </aecom-button>
            </div>
          </aecom-form-item>
        </aecom-form-group>
      </ng-container>

      <ng-container *ngIf="!isRecalled">
        <div class="tab-container">
          <div class="tab-header">
            <span class="tab-title" *ngIf="!IsReject && !IsCancel">
              RFI Response
            </span>

            <span class="tab-title" *ngIf="IsReject || IsCancel">
              QA/QC Failed Note by :
              <span class="color-red">{{ rejectedBy }}</span>
            </span>
          </div>
        </div>

        <div class="bottom-padding pt-24" *ngIf="IsReject || IsCancel">
          <div class="content-value" [innerHtml]="rejectedNotes">
          </div>
        </div>

        <div
          class="text-center"
          style="padding-top: 35px"
          *ngIf="!IsReject && !IsCancel && !IsResponded"
        >
          <div class="rfi-response-padding">
            <i class="icon icon-action-basic_todo"></i>
            <div class="rfi-response-text rfi-response-text-padding">
              No Response Yet
            </div>
          </div>
        </div>

        <div
          style="padding-top: 35px"
          class="bottom-padding"
          *ngIf="!IsReject && !IsCancel && IsResponded"
        >
          <span class="content-header">Response</span>
          <div class="content-value" [innerHtml]="rfi.OfficialResponse">
          </div>
        </div>

        <div
          style="padding-top: 35px"
          class="bottom-padding"
          *ngIf="!IsReject && !IsCancel && IsResponded"
        >
          <bbj-file-list
            title="Attachments"
            [fileList]="docFiles"
            [allowMovingFiles]="false"
          ></bbj-file-list>
        </div>

        <reopen-detail *ngIf="showReopenItem" [reopen]="reopenItem" [readonly]="true" style="grid-column: span 12; margin: 40px 0; display: block;"></reopen-detail>

        <aecom-form-group
          *ngIf="showReopenAcknowledge"
          columnSpan="12"
          columns="4"
          title="Acknowledge Change?"
          class="headerSubTitle"
          style="margin: 40px 0; display: block;"
        >
          <aecom-form-item data-cy="acknowledge" columnSpan="1">
            <div class="button-wrapper d-flex">
              <aecom-button
                innerClass="btn solid btn-tab blue"
                (click)="reopenApproval()"
              >
                Yes
              </aecom-button>
            </div>
          </aecom-form-item>
        </aecom-form-group>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #notAvailable>
  <div class="itemText">N/A</div>
</ng-template>
<div *ngIf="showReport" class="up-contentcontainer">
  <app-report [rfi]="rfi" [rfiDocuments]="rfiDocuments" (closeWindow)="closeReport($event)"></app-report>
</div>