/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/cognitive-complexity */
import { canEditRFI, getCostImpact, getIcon, getRFIlink, getScheduleImpace, getStatusBeforeReopen, getStatusText, isFinalStep, isReviewer } from "@shared/utils";
import { IPRFIListItem, IUser, TPContractUser } from "../api-generated";
import RFIRole from "./rfiRoles";
import RFIStatus from "./rfiStatus";
import LocalApplicationRoleService from "@services/local-application_role.service";

export default class RFIListRowItem {
  public id: string;

  public priority: number;

  public rfiNumber: string;

  public rfiTitle: string;

  public specification: string;

  public division: string;

  public url: string;

  public ref: string;

  public classification: string;

  public contractRequirement: string;

  public phase: string;

  public anticipatedScheduleImpact: string;

  public anticipatedCostImpact: string;

  public processingNotes: string;

  public masterSpec: string;

  public dueOn: string;

  public issuedOn: string;

  public issuedBy: string;

  public reponseDate: string;

  public ballInCourt: string;

  public status: string;

  public response: string;

  public isHidden: boolean;

  public viewOnly: boolean;

  public btnText: string;

  public btnClass: string;

  public btnUrl: string;

  public compilingValue: number;

  public internalDueOn: string;

  public statusText: string;

  public rfiIcon: string;

  public ballInCourtInfo?: IUser;

  public showRecallRejected?: boolean;

  public reviewers: string[];

  public initSort: number;

  public bicSort: string;

  public manager: string;

  public coordinator: string;

  public ddcManager: string;

  public question: string;

  public answer: string;

  public altUrl?: string;

  public amended: boolean;

  public scheduleImpact?: string;
  
  public costImpact?: string;

  constructor(
    localApplicationRoleService: LocalApplicationRoleService,
    contractId: string,
    currentuserId: string,
    item: IPRFIListItem,
    role: string,
    users: IUser[],
  ) {
    this.id = item.Guid;
    this.priority = item.Priority;
    this.rfiNumber = item.RFINumber;
    this.rfiTitle = item.Title;
    this.question = item.Question;
    this.answer = item.OfficialResponse ?? "-";
    this.url = item.ReferenceURL ?? "-";
    this.ref = item.ContractorReference ?? "-";
    this.dueOn = item.DueDate ?? "-";
    this.issuedOn = item.IssueDate ?? "-";
    this.reponseDate =
      item.Status === RFIStatus.Responded ? item.DateUpdated : "-";
    this.ballInCourt = item.BallInCourt;
    const bicUser = users.find((cu) => {
      return cu.id === item.BallInCourtId;
    });
    this.bicSort = bicUser?.displayName ?? localApplicationRoleService.getRoleById(this.ballInCourt);
    if (bicUser) {
      this.ballInCourtInfo = bicUser;
    }
    this.status = item.Status;
    const hasReviewerRole = isReviewer(item, currentuserId);
    if(item.Guid === 'c5426411-a68c-4449-85db-dabc45e72159')
    {
      console.log(item);
    }
    this.viewOnly = !canEditRFI(item, role, currentuserId, hasReviewerRole);
    this.response = this.viewOnly ? "0" : "1";
    this.isHidden = false;
    this.compilingValue = -1;
    if(item.Status === RFIStatus.Review_Period)
    {
      if (item.rfi_reviewer && item.rfi_reviewer.length > 0) {
        this.compilingValue = Math.round(
          (item.rfi_reviewer.filter((reviewer) => {
            return (
              reviewer.hasComments === false ||
              (reviewer.Response &&
                reviewer.Response.trim() !== "" &&
                reviewer.IsDraft === false)
            );
          }).length /
            item.rfi_reviewer.length) *
            100,
        );
      }
    }
    this.internalDueOn = item.InternalDueDate ?? "-";
    this.showRecallRejected =
      item.IsRecalled !== undefined &&
      item.ShowRecallRejected &&
      item.IssueBy === currentuserId;

    this.specification = item.rfi_specification
      ? `${item.rfi_specification.SpecId} - ${item.rfi_specification.Title}`
      : "";
    this.division = item.rfi_specification
      ? item.rfi_specification.Division
      : "";

    this.classification = item.rfi_classification
      ? item.rfi_classification.Name
      : "";
    this.contractRequirement = item.rfi_contract_requirement?.Name ?? "-";

    this.phase = item.phase ? `${item.phase.Title}` : "N/A";

    this.anticipatedScheduleImpact =
      item.AnticipatedScheduleImpact !== null
        ? item.AnticipatedScheduleImpact === true
          ? "Yes"
          : "No"
        : "-";

    this.anticipatedCostImpact =
      item.AnticipatedCostImpact !== null
        ? item.AnticipatedCostImpact === true
          ? "Yes"
          : "No"
        : "-";

    this.processingNotes = item.ProcessingNotes ?? "-";

    this.masterSpec =
      item.rfi_specification_rfi_item_MasterSpecIdTorfi_specification
        ? `${item.rfi_specification_rfi_item_MasterSpecIdTorfi_specification.SpecId} - ${item.rfi_specification_rfi_item_MasterSpecIdTorfi_specification.Title}`
        : "-";

    if (item.rfi_reviewer && item.rfi_reviewer.length > 0) {
      this.reviewers = item.rfi_reviewer
        .filter((r) => {
          return !r.DeletedBy;
        })
        .map((r) => {
          const userInfo = users.find((c) => {
            return c.id === r.ReviewerId;
          });
          return userInfo ? `${userInfo.displayName}: ${userInfo.mail}` : "";
        });
    } else {
      this.reviewers = [];
    }

    let isRep = false;
    let newReview = false;
    if(currentuserId !== item.CoordinatorId && item.Status === RFIStatus.Review_Period)
    {
      const myReview = item.rfi_reviewer?.find((r) => {
        return r.ReviewerId === currentuserId;
      });
      if (myReview) {
        isRep =
          myReview.hasComments === false ||
          (myReview.Response &&
            myReview.Response.trim().length > 0 &&
            myReview.IsDraft !== true);
        newReview = !isRep;
      }
    }
    
    
    this.btnClass = RFIListRowItem.getBtnClass(this.viewOnly, isRep, newReview);
    this.initSort = RFIListRowItem.getInitSort(this.viewOnly, isRep);

    this.btnText = RFIListRowItem.getBtnText(item, currentuserId, this.viewOnly);
    this.btnUrl = getRFIlink(contractId, item, this.viewOnly, hasReviewerRole);
    this.amended = RFIListRowItem.isAmended(item);

    if (
      currentuserId === item.CoordinatorId &&
      item.Status === RFIStatus.Review_Period &&
      item.IsRecalled !== true && hasReviewerRole
    ) {
      this.altUrl = this.btnUrl;
      this.btnUrl = `/${contractId}/compilingResponse/${item.Guid}`;
    }
    
    const isDB = role === RFIRole.DBAdmin;
    this.statusText = getStatusText(item, isDB);
    this.rfiIcon = getIcon(item, isDB);

    const showManager = RFIListRowItem.shouldShowManager(item);
    const showCoordinator = RFIListRowItem.shouldShowCoordinator(item);
    const showDDCManager = RFIListRowItem.shouldShowDDCManager(item);
    this.manager = RFIListRowItem.getUserString(
      item.ManagerId,
      users,
      showManager,
    );
    this.coordinator = RFIListRowItem.getUserString(
      item.CoordinatorId,
      users,
      showCoordinator,
    );
    this.ddcManager = RFIListRowItem.getUserString(
      item.DDCManagerId,
      users,
      showDDCManager,
    );

    if(getStatusBeforeReopen(item) === RFIStatus.Responded)
    {
      const finalCompile = item.rfi_compiled_response
        .find((c) => {
          return !c.IsDraft && c.DeletedBy !== null && c.To === RFIStatus.Responded;
        });
        if(finalCompile)
        {
          this.scheduleImpact = getScheduleImpace(finalCompile);
          this.costImpact = getCostImpact(finalCompile);
        }
    }
  }

  static getBtnText(item: IPRFIListItem, userId: string, viewOnly: boolean): string {
    const view = "View";
    const review = "Review";
    const qaqc = "QA/QC";
    const edit = "Edit";
    const compile = "Compile";
    const respond = "Respond";
    const unknow = "Unknow";
    if(viewOnly)
    {
      return view;
    }
    else if (item.IsRecalled) {
      return review;
    } else {
      switch (item.Status) {
        case RFIStatus.Submitted:
          return qaqc;
        case RFIStatus.Review_Period:
          if (userId === item.BallInCourtId) {
            return compile;
          } else {
            return respond;
          }
        case RFIStatus.Distribution:
        case RFIStatus.QAQC_Fail:
        case RFIStatus.PM_Review:
        case RFIStatus.DDC_Review:
        case RFIStatus.SPM_Review:
        case RFIStatus.Request_to_Reopen:
          return review;
        
        case RFIStatus.Draft:
          return edit;
      }
    }
  }

  static getBtnClass(viewonly: boolean, isRepresent: boolean, isNewReview: boolean) {
    if(viewonly)
    {
      return "btn outline lg blue";
    }
    if(isNewReview)
    {
      return "btn solid lg newReviewBottonColor";
    }
    if(isRepresent)
    {
      return "btn solid lg altBottonColor";
    }
    return "btn solid lg blue";
  }

  static getInitSort(viewonly: boolean, isRepresent: boolean): number {
    return viewonly ? 0 : isRepresent ? 1 : 2;
  }

  private static getWorkflowStatusArray(rfi: IPRFIListItem): string[] {
    if (rfi?.rfi_flow) {
      return rfi?.rfi_flow.map((f) => {
        return f.Status;
      });
    }
    return [];
  }

  private static shouldShowManager(rfi: IPRFIListItem): boolean {
    const shouldShow = rfi?.rfi_flow.find((f) => {
      return f.BIC === RFIRole.Manager && f.Status === RFIStatus.PM_Review;
    });
    return (
      (shouldShow !== null && shouldShow !== undefined) ||
      isFinalStep(rfi.Status)
    );
  }

  private static shouldShowCoordinator(rfi: IPRFIListItem): boolean {
    return (
      RFIListRowItem.getWorkflowStatusArray(rfi).includes(
        RFIStatus.Distribution,
      ) || isFinalStep(rfi.Status)
    );
  }

  private static shouldShowDDCManager(rfi: IPRFIListItem): boolean {
    return (
      RFIListRowItem.getWorkflowStatusArray(rfi).includes(
        RFIStatus.DDC_Review,
      ) || isFinalStep(rfi.Status)
    );
  }

  private static getUserString(
    userId: string | undefined,
    users: IUser[],
    show: boolean,
  ): string {
    const result = show ? "N/A" : "-";
    if (userId) {
      const userInfo = users.find((u) => {
        return u.id === userId;
      });
      return userInfo ? `${userInfo.displayName}: ${userInfo.mail}` : result;
    }
    return result;
  }

  private static isAmended(item: IPRFIListItem): boolean {
    const reopen = item.rfi_reopen?.sort((a,b)=>new Date(a.DateRequest).getTime()-new Date(b.DateRequest).getTime());
    if(reopen?.length > 0)
    {
      const closeStatusArr: string[] = [RFIStatus.Canceled, RFIStatus.Responded, RFIStatus.Superseded];
      const reopenTime = new Date(reopen[0].DateRequest).getTime();
      const flow = item.rfi_flow?.find((f)=>{return new Date(f.DateReceived).getTime() > reopenTime && closeStatusArr.includes(f.Status) });
      if(flow)
      {
        return true;
      }
    }
    return false;
  }
}
