import { getLatestDueDateItem } from "@shared/utils";
import { IPRFIItem, IRFIUpdateDueDate } from "../api-generated";
import RFIDueDateDetails from "./rfiDueDateDetail";
import RFIUpdateBase from "./rfiUpdateBase";

export default class RFIUpdateDueDateStep
  extends RFIUpdateBase
  implements IRFIUpdateDueDate
{
  public rfi_duedate: RFIDueDateDetails;

  constructor(contractId: string, rfi: IPRFIItem) {
    super(contractId, rfi);
    const lastDueDateItem = getLatestDueDateItem(rfi);
    this.rfi_duedate = new RFIDueDateDetails(lastDueDateItem);
    delete this.rfi_duedate.Guid;
  }
}
