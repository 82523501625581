<div class="maincontainer" *ngIf="baseFunctionService.reviewersResponse">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel 
        [defaultTab]="defaultTab" 
        [allowMovingFiles]="true" 
        [reviewersResponse]="baseFunctionService.reviewersResponse"
        [watchersResponse]="baseFunctionService.watchersResponse"
        (moveAttachment)="addFileToAttachment($event)"
        (moveAttachments)="addFilesToAttachment($event)"
      ></leftPanel>
      
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button innerClass="btn lg outline orange" (click)="back()"> back </aecom-button>

              <aecom-button
                *ngIf="!isDDCReview"
                innerClass="btn lg outline blue"
                (click)="reassign()"
                data-cy="reassign"
              >
                Reassign
              </aecom-button>

              <aecom-button
                [theme]="'light'"
                [disabled]="!hasChanges() || needDelegate ? true : null"
                innerClass="btn lg solid green"
                (click)="saveDraft()"
              >
                save draft
              </aecom-button>

              <aecom-button innerClass="btn lg solid blue" (click)="submitRFI()"> Submit </aecom-button>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="rfi">
        <div class="col-12" *ngIf="rfi.rfi_compiled_response">
          <aecom-form columns="6">
            <aecom-form-group
              *ngIf="!isCompile"
              columnSpan="6"
              columns="2"
              [title]="'Processing'"
              class="matchToTab mb24"
            >
              <ng-container *ngIf="isDDCReview">
                <aecom-form-item
                  data-cy="delegate"
                  [label]="'1. Would you like to delegate this task to another DDC Manager?'"
                  [required]="true"
                  columnSpan="1"
                >
                  <div class="button-wrapper d-flex">
                    <aecom-button
                      innerClass="btn solid btn-tab {{
                        needDelegate
                          ? 'blue'
                          : 'bg-grey-e'
                      }}"
                      (click)="setNeedDelegate(true)"
                    >
                      Yes
                    </aecom-button>

                    <aecom-button
                      innerClass="btn solid btn-tab {{
                        !needDelegate
                          ? 'blue'
                          : 'bg-grey-e'
                      }}"
                      (click)="setNeedDelegate(false)"
                    >
                      No
                    </aecom-button>
                  </div>
                </aecom-form-item>
                <ng-container
                  *ngIf="needDelegate; else itemPlaceHolder"
                >
                  <aecom-form-item
                    id="delegateTo"
                    [label]="'Delegate To'"
                    [required]="true"
                    columnSpan="1"
                  >
                    <aecom-dropdown
                      data-cy="delegateTo"
                      [theme]="'light'"
                      placeholder="Select"
                      [data]="delegateList"
                      (ngValueChange)="setUserSelect($event)"
                      [selectedId]="delegateItem.rfi_delegate_detail.UserId"
                      [validate]="canIssueRFI && !delegateItem.rfi_delegate_detail.UserId"
                    ></aecom-dropdown>
                  </aecom-form-item>
                </ng-container>
              </ng-container>
              <aecom-form-item
                *ngIf="!needDelegate"
                [label]="isDDCReview ? '2.Do you accept the draft response from AHJV?' : '1. Do you accept compiled response?'"
                columnSpan="1"
              >
                <div class="button-wrapper d-flex">
                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      rfi.DosePMCAccept === true ? 'blue' : 'bg-grey-e'
                    }}"
                    (click)="setPMCAccept(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      rfi.DosePMCAccept === false ? 'blue' : 'bg-grey-e'
                    }}"
                    (click)="setPMCAccept(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>
              <aecom-form-item columnSpan="1">
              </aecom-form-item>
              <ng-container *ngIf="isPMReview && rfi.DosePMCAccept === true">
                <aecom-form-item
                  data-cy="toSrManager"
                  [label]="'2.Does this require review by Sr. PM?'"
                  [required]="true"
                  columnSpan="1"
                  theme="light"
                >
                  <div class="button-wrapper d-flex">
                    <aecom-button
                      [theme]="'light'"
                      innerClass="btn solid btn-tab {{
                        rfi.ToSrManager
                          ? 'blue selectedBtn'
                          : 'bg-grey-e unSelectedBtn'
                      }}"
                      (click)="setSendToSrManager(true)"
                    >
                      Yes
                    </aecom-button>

                    <aecom-button
                      [theme]="'light'"
                      innerClass="btn solid btn-tab {{
                        !rfi.ToSrManager
                          ? 'blue selectedBtn'
                          : 'bg-grey-e unSelectedBtn'
                      }}"
                      (click)="setSendToSrManager(false)"
                    >
                      No
                    </aecom-button>
                  </div>
                </aecom-form-item>
                <ng-container
                  *ngIf="rfi.ToSrManager; else itemPlaceHolder"
                >
                  <aecom-form-item
                    id="srManager"
                    [label]="'Sr. PM'"
                    [required]="true"
                    columnSpan="1"
                  >
                    <aecom-dropdown
                      data-cy="srManager"
                      [theme]="'light'"
                      placeholder="Select"
                      [data]="srManagerList"
                      (ngValueChange)="setSrManager($event)"
                      [selectedId]="rfi.SrManagerId"
                      [validate]="canIssueRFI && !rfi.SrManagerId"
                    ></aecom-dropdown>
                  </aecom-form-item>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!rfi.ToSrManager && !isDDCReview && rfi.DosePMCAccept === true">
                <aecom-form-item
                  data-cy="toDDC"
                  [label]="(!isPMReview ? '2' : '3')+'. Does this require a final review by DDC?'"
                  [required]="true"
                  columnSpan="1"
                >
                  <div class="button-wrapper d-flex">
                    <aecom-button
                      innerClass="btn solid btn-tab {{
                        rfi.ToDDC
                          ? 'blue'
                          : 'bg-grey-e'
                      }}"
                      (click)="setSendToDDC(true)"
                    >
                      Yes
                    </aecom-button>

                    <aecom-button
                      innerClass="btn solid btn-tab {{
                        !rfi.ToDDC
                          ? 'blue'
                          : 'bg-grey-e'
                      }}"
                      (click)="setSendToDDC(false)"
                    >
                      No
                    </aecom-button>
                  </div>
                </aecom-form-item>
                <ng-container
                  *ngIf="rfi.ToDDC; else itemPlaceHolder"
                >
                  <aecom-form-item
                    id="ddcManager"
                    [label]="'DDC Manager'"
                    [required]="true"
                    columnSpan="1"
                  >
                    <aecom-dropdown
                      data-cy="ddcManager"
                      [theme]="'light'"
                      placeholder="Select"
                      [data]="ddcManagersList"
                      (ngValueChange)="setDDCManager($event)"
                      [selectedId]="rfi.DDCManagerId"
                      [validate]="canIssueRFI && !rfi.DDCManagerId"
                    ></aecom-dropdown>
                  </aecom-form-item>
                </ng-container>
              </ng-container>
              
            </aecom-form-group>
            <ng-container *ngIf="!needDelegate">
              <aecom-form-group
                columnSpan="6"
                columns="2"
                [title]="getResponseTitle()"
                class="matchToTab mb24"
              >
                <ng-container *ngIf="isCompile || rfi.DosePMCAccept === true">
                  <aecom-form-item label="Schedule Impact (Internal)" columnSpan="1">
                    <div class="button-wrapper d-flex">
                      <ng-container *ngFor="let option of scheduleImpactOptions">
                        <aecom-button
                          innerClass="btn solid btn-tab {{
                            rfi.rfi_compiled_response.ScheduleImpact === (option.id==='2') ? 'blue' : 'bg-grey-e'
                          }}"
                          (click)="setScheduleImpact(option.id==='2')"
                        >
                          {{option.name}}
                        </aecom-button>
                      </ng-container>
                    </div>
                  </aecom-form-item>
                  <!-- <aecom-form-item columnSpan="1">
                  </aecom-form-item> -->
                  <aecom-form-item label="Cost Impact (Internal)" columnSpan="1">
                    <div class="button-wrapper d-flex">
                      <ng-container *ngFor="let option of costImpactOptions">
                        <aecom-button
                          innerClass="btn solid btn-tab {{
                            rfi.rfi_compiled_response.CostImpact === option.id ? 'blue' : 'bg-grey-e'
                          }}"
                          (click)="setCostImpact(option.id)"
                        >
                          {{option.name}}
                        </aecom-button>
                      </ng-container>
                    </div>
                  </aecom-form-item>
                  <!-- <aecom-form-item columnSpan="1">
                  </aecom-form-item> -->
                  <aecom-form-item
                    columnSpan="2"
                    [label]="'Response'"
                    [property]="rfi.rfi_compiled_response.Response.length + '/' + responseMaxLength + ' characters'"
                    [required]="true"
                  >
                    <aecom-textbox
                      [theme]="'light'"
                      [(ngValue)]="rfi.rfi_compiled_response.Response"
                      [notEmpty]="true"
                      rows="6"
                      [maxlength]="responseMaxLength"
                      [isMultiLine]="true"
                      (ngValueChange)="setResponse($event)"
                      [validate]="(!rfi.rfi_compiled_response.Response?.length || getResponseLength() > responseMaxLength) && canIssueRFI || baseFunctionService.isInputInvalid(rfi.rfi_compiled_response.Response)"
                    ></aecom-textbox>
                  </aecom-form-item>
                  <div style="grid-column: span 2;">
                    <aecom-checkbox
                      [ngValue]="acknowledge"
                      (ngValueChange)="setAcknowledge($event)"
                      [validate]="acknowledge !== true && canIssueRFI"
                    >
                      I acknowledge that once I have submitted my response, I will no longer be able to edit.
                    </aecom-checkbox>
  
                    <div class="error-message" *ngIf="acknowledge !== true && canIssueRFI">Required Field</div>
                  </div>
                </ng-container>
                <aecom-form-item
                  *ngIf="!isCompile && rfi.DosePMCAccept !== true"
                  columnSpan="2"
                  [label]="'Reason'"
                  [property]="reason.length + '/' + responseMaxLength + ' characters'"
                  [required]="true"
                >
                  <aecom-textbox
                    [theme]="'light'"
                    [(ngValue)]="reason"
                    [notEmpty]="true"
                    placeholder="Please enter the reason"
                    rows="6"
                    [maxlength]="responseMaxLength"
                    [isMultiLine]="true"
                    (ngValueChange)="setReason($event)"
                    [validate]="(!reason?.length || getReasonLength() > responseMaxLength) && canIssueRFI || baseFunctionService.isInputInvalid(reason)"
                  ></aecom-textbox>
                </aecom-form-item>
              </aecom-form-group>

              <aecom-form-group columnSpan="6" columns="2" title="Attachments" style="margin-bottom: 40px" *ngIf="isCompile || rfi.DosePMCAccept === true">
                <aecom-form-item>
                  <aecom-upload
                    [isNewStyle]="true"
                    [multiFiles]="true"
                    [canDelete]="true"
                    [data]="(baseFunctionService.getBBJFileListItemAttachment().fileList$ | async)"
                    [theme]="'light'"
                    [labelText]="'Uploaded Attachments'"
                    (ngValueChange)="importFile($event)"
                    (ngDeleteChange)="deleteFile($event)"
                  ></aecom-upload>
                  <div class="error-message" *ngIf="!(baseFunctionService.getBBJFileListItemAttachment().fileListNameValid$ | async)">
                    File Name cannot include special characters other than '-', '_', '.', '@'
                  </div>
                </aecom-form-item>
              </aecom-form-group>
            </ng-container>
            
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="bbjSelectUserService.workingItem" class="up-contentcontainer">
  <div class="selectUserWindow">
    <bbj-select-user (close)="baseFunctionService.closeWindowDirectSaveToDB($event)"></bbj-select-user>
  </div>
</div>

<ng-template #itemPlaceHolder>
  <aecom-form-item columnSpan="1">
  </aecom-form-item>
</ng-template>