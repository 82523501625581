import { FileModel, ModalContainerService } from "@aecom/core";
import { Component } from "@angular/core";
import { BBJFileListService } from "@bbj/components";
import RFIFileType from "@models/RFIFileType";
import RFIReopenStep from "@models/rfiReopenStep";
import RFIRole from "@models/rfiRoles";
import BaseFunctionService from "@services/baseFunction.service";
import LocalContractUserService from "@services/local-contractUser.service";
import _ from "lodash";

@Component({
  selector: "app-reopen-modal",
  templateUrl: "./reopen-modal.component.html",
  styleUrls: ["./reopen-modal.component.scss"]
})
export class ReopenModalComponent {
  item: RFIReopenStep;
  attemptToIssue = false;

  constructor(
    private activeModal: ModalContainerService,
    public bbjFileListService: BBJFileListService,
    public localContractUserService: LocalContractUserService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  get rfiFileType(): typeof RFIFileType {
    return RFIFileType;
  }

  save() {
    this.attemptToIssue = true;

    if (this.bbjFileListService.hasFilesUploading()) {
      this.bbjFileListService.showFileProgressNotification();
    } else if (this.isValid()) {
      if(!this.item.rfi_reopen.ChangeType.includes('other'))
      {
        delete this.item.rfi_reopen.OtherChangeType;
      }
      this.bbjFileListService.updateFiles(this.item);
      this.activeModal.close(this.item);
    }
  }

  isValid(): boolean {
    const role = this.localContractUserService.currentUserContractRole;
    const isDoc = role === RFIRole.Doc_Control;
    return this.item.rfi_reopen.ChangeType?.length > 0 
      && this.item.rfi_reopen.Reason?.length > 0 
      && this.item.rfi_reopen.Reason.length <= 2000 
      && this.bbjFileListService.isFileNameValid()
      && (isDoc ? !_.isEmpty(this.item.rfi_reopen.DirectedBy) : true)
      // && this.item.rfi_reopen.Significance != undefined
  }

  cancel() {
    this.activeModal.close(null);
  }

  importFile(e: FileModel[]): void {
    this.baseFunctionService.importFile(e);
  }

  deleteFile(e: FileModel): void {
    this.baseFunctionService.deleteFile(e);
  }
}
