/* eslint-disable security/detect-object-injection */
/* eslint-disable security/detect-non-literal-fs-filename */
import { FileModel } from "@aecom/core";
import { Component, Input, OnInit } from "@angular/core";
import LocalUserService from "@services/local-user.service";
import { mapDocumentsToFile } from "@shared/utils";
import {
  IPRFIDocumentDownload,
  IPRFIItem,
  IUser,
} from "src/app/api-generated";
import RFIDetail from "src/app/models/rfiDetail";
import RFIStatus from "src/app/models/rfiStatus";

@Component({
  selector: "rfi-detail",
  templateUrl: "./rfi-detail.component.html",
  styleUrls: ["./rfi-detail.component.scss"],
})
export default class RFIDetailComponent implements OnInit {
  @Input() entity: IPRFIItem;

  @Input() docs: IPRFIDocumentDownload[] = [];

  @Input() IsDbAdmin = false;

  rfiDetail: RFIDetail;

  docFiles: FileModel[] = [];

  users: IUser[] = [];

  getFileIcon = RFIDetailComponent.getFileIcon;

  constructor(public localUserService: LocalUserService){}

  ngOnInit(): void {
    this.users = this.localUserService.getItems();
    this.rfiDetail = new RFIDetail(this.entity, this.users);
    const documentsForThis = this.docs.filter((item)=>{return item.Status === RFIStatus.Draft});
    this.docFiles = mapDocumentsToFile(documentsForThis);
  }

  static getFileIcon(type: string): string {
    return type.includes("image")
      ? " icon icon-image_doc"
      : " icon icon-blank_doc";
  }
}
