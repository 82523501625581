import { IRFIDueDate, RfiDuedate } from "../api-generated";

export default class RFIDueDateDetails
  implements IRFIDueDate
{
  public Guid?: string;
  public DueDate?: string | null;
  public InternalDueDate?: string | null;

  constructor(item?: RfiDuedate) {

    this.Guid = item?.Guid;

    this.DueDate = item?.DueDate;

    this.InternalDueDate = item?.InternalDueDate;
  }
}
